.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 0.5rem;
    line-height: 1.1;
    color: inherit;
}
.h1,
h1 {
    font-size: 2rem;
}
.h2,
h2 {
    font-size: 1.75rem;
}
.h3,
h3 {
    font-size: 1.5rem;
}
.h4,
h4 {
    font-size: 1.25rem;
}
.h5,
h5 {
    font-size: 1rem;
}
.h6,
h6 {
    font-size: 0.875rem;
}

a {
    text-decoration: none;
    color: inherit;
}

.caption {
    font: $font-caption;
}
.subtitle-1 {
    font: $font-subtitle-1;
}
.subtitle-2 {
    font: $font-subtitle-2;
}
.heading {
    font: $font-heading;
}
.title {
    font: $font-title;
}
.display-1 {
    font: $font-display-1;
}
.display-2 {
    font: $font-display-2;
}
.display-3 {
    font: $font-display-3;
}
.display-4 {
    font: $font-display-4;
}

.capitalize {
    text-transform: capitalize !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.lowercase {
    text-transform: lowercase !important;
}

// font weight
.font-normal {
    font-weight: normal !important;
}
.font-light {
    font-weight: 300 !important;
}
.font-medium {
    font-weight: 500 !important;
}
.font-semibold {
    font-weight: 600 !important;
}
.font-bold {
    font-weight: 700 !important;
}

// font size

.text-13 {
    font-size: 13px;
}
.text-14 {
    font-size: 14px;
}
.text-16 {
    font-size: 16px;
}
.text-18 {
    font-size: 18px;
}
.text-20 {
    font-size: 20px;
}
.text-22 {
    font-size: 22px;
}
.text-24 {
    font-size: 24px;
}
.text-30 {
    font-size: 30px !important;
}
.text-32 {
    font-size: 32px;
}
.text-small {
    font-size: 0.8125rem !important; // 13px
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
    word-break: break-word;
}

.whitespace-pre {
    white-space: pre;
}
.whitespace-no-wrap {
    white-space: nowrap;
}
