body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px; /*/maybe not needed*/
    overflow: hidden;/*/maybe not needed*/
    position: relative; /*/maybe not needed*/
}

.leaflet-container {
    width: 'max-content';
    height: 60vh;
    margin-top: 1vh;
    margin-bottom: 0.5vh;
    margin-left: 0.75vw;
    margin-right: 0.75vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    color: rgba(255, 0, 221, 0.863);/*/maybe not needed*/
    font-size: 16px; /*/maybe not needed*/
    /* color: rgba(255, 162, 0, 0.842);*/
}
