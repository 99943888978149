// Spacing

@mixin generate-margin-padding-in-rem($from, $to) {
    @for $i from $from through $to {
        .m-#{$i} {
            margin: #{$i * 0.25}rem;
        }
        .mt-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
        }
        .mr-#{$i} {
            margin-right: #{$i * 0.25}rem !important;
        }
        .mb-#{$i} {
            margin-bottom: #{$i * 0.25}rem !important;
        }
        .ml-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
        }
        .mx-#{$i} {
            margin-left: #{$i * 0.25}rem !important;
            margin-right: #{$i * 0.25}rem !important;
        }
        .my-#{$i} {
            margin-top: #{$i * 0.25}rem !important;
            margin-bottom: #{$i * 0.25}rem !important;
        }

        .p-#{$i} {
            padding: #{$i * 0.25}rem !important;
        }
        .pt-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
        }
        .pr-#{$i} {
            padding-right: #{$i * 0.25}rem !important;
        }
        .pb-#{$i} {
            padding-bottom: #{$i * 0.25}rem !important;
        }
        .pl-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
        }
        .px-#{$i} {
            padding-left: #{$i * 0.25}rem !important;
            padding-right: #{$i * 0.25}rem !important;
        }
        .py-#{$i} {
            padding-top: #{$i * 0.25}rem !important;
            padding-bottom: #{$i * 0.25}rem !important;
        }
    }
}

@mixin generate-margin-padding-in-px($from, $to) {
    @for $i from $from through $to {
        .m-#{$i}px {
            margin: #{$i}px;
        }
        .mt-#{$i}px {
            margin-top: #{$i}px !important;
        }
        .mr-#{$i}px {
            margin-right: #{$i}px !important;
        }
        .mb-#{$i}px {
            margin-bottom: #{$i}px !important;
        }
        .ml-#{$i}px {
            margin-left: #{$i}px !important;
        }
        .mx-#{$i}px {
            margin-left: #{$i}px !important;
            margin-right: #{$i}px !important;
        }
        .my-#{$i}px {
            margin-top: #{$i}px !important;
            margin-bottom: #{$i}px !important;
        }

        .p-#{$i}px {
            padding: #{$i}px !important;
        }
        .pt-#{$i}px {
            padding-top: #{$i}px !important;
        }
        .pr-#{$i}px {
            padding-right: #{$i}px !important;
        }
        .pb-#{$i}px {
            padding-bottom: #{$i}px !important;
        }
        .pl-#{$i}px {
            padding-left: #{$i}px !important;
        }
        .px-#{$i}px {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }
        .py-#{$i}px {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }
    }
}

@mixin generate-height-width($from, $to) {
    @for $i from $from through $to {
        @if $i % 4 == 0 {
            .w-#{$i} {
                width: #{$i}px !important;
            }
            .min-w-#{$i} {
                min-width: #{$i}px !important;
            }
            .max-w-#{$i} {
                max-width: #{$i}px !important;
            }
            .h-#{$i} {
                height: #{$i}px !important;
            }
            .min-h-#{$i} {
                min-height: #{$i}px !important;
            }
            .max-h-#{$i} {
                max-height: #{$i}px !important;
            }
        }
    }
}

// media
@mixin media($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

// Animation
@mixin keyframeMaker($name) {
    @keyframes #{$name} {
        @content;
    }
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
}
